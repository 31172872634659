@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Kaushan+Script&family=Overpass:wght@400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,600&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Overpass", sans-serif;
}
.flex {
  display: flex;
}
a.logo_link {
  text-decoration: none;
}

img,
span {
  display: block;
}

.burger-menu {
  display: none;
}

.logo_2 img {
  width: 70px;
}
.logo_2 {
  align-items: center;
}

.logo_2 p {
  width: 200px;
  font-family: "Great Vibes", cursive;
  color: white;
  font-size: 40px;
  padding-left: 3%;
  margin-top: 6%;
}
nav {
  display: flex;
  height: 80px;
  width: 100%;
  background: #262674;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em 0 2em;
  flex-wrap: wrap;
}

nav ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

nav ul li a {
  display: inline-block;
  color: #f2f2f2;
  text-decoration: none;
  font-size: 17px;
  font-weight: 600;
  padding: 1.1rem;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  font-family: "Overpass", sans-serif;
}

#nav-menu a::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #e69090;
  transition: width 0.4s ease-in;
}

#nav-menu a:hover::after {
  width: 100%;
}
.tell {
  border: 1px color #e7e7ebcc;
}
nav .menu-btn i {
  color: #fff;
  font-size: 22px;
  cursor: pointer;
  display: none;
}

.section_main {
  width: 100%;
  padding-bottom: 40px;
  background-image: url(/src/img/first_page/front.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}
.section_main h2 {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  padding-top: 40px;
  font-family: Overpass, sans-serif;
  font-size: 2.3em;
}

h1 {
  color: white;
  font-family: "Overpass", sans-serif;
  font-size: 70px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 70px;
}
.section_main {
  align-items: center;
}
.section_main img {
  width: 100px;
  margin: 5px auto;
}
.first-page-btn {
  display: block;
  width: 20%;
  margin: 40px auto;
  text-align: center;
  text-decoration: none;
  border: none;
  font-size: 1.2em;
  color: #ffffff;
  padding: 16px 16px;
  background-color: #262674;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.first-page-btn:hover {
  -webkit-box-shadow: inset -1px 3px 8px 5px #1f87ff, 2px 5px 16px 0px #0b325e,
    -50px -50px 0px -30px rgba(231, 255, 69, 0);
  box-shadow: inset -1px 3px 8px 5px #0d65ca, 2px 5px 16px 0px #0b325e,
    -50px -50px 0px -30px rgba(231, 255, 69, 0);
}
.section_main h3 {
  color: white;
  font-family: "Overpass", sans-serif;
  font-size: 40px;
  text-align: center;
  margin-top: 20px;
}

.service {
  width: 100%;

  margin: 50px auto;
  justify-content: center;
}

.item {
  width: 300px;
  margin: 20px;
  --clr: #262674;
  --outline: 2px solid white;
  border: 2px solid var(--clr);
  background: var(--clr);
  border-radius: 20px;
  color: white;
  outline: var(--outline);
  transition: outline-offset 200ms ease;
  box-shadow: -14px 13px 15px 3px rgba(74, 117, 125, 1);
}

.item:hover,
.item:focus {
  outline: var(--outline);
  outline-offset: -6px;
}

.box img {
  width: 92px;
}
.item img {
  margin: 40px auto;
}
.item h2 {
  text-align: center;
}
.item p {
  padding: 20px 30px;
  text-align: center;
}

.about {
  width: 100%;
  background: #262674;
  color: white;
  margin-top: 50px;
  justify-content: space-around;
}
.logo2 {
  margin-top: 40px;
}
.logo2 img {
  width: 170px;
  margin-bottom: 22px;
}
.logo2 p {
  text-shadow: 5px 5px 3px rgb(55, 55, 65);
  width: 200px;
  font-family: "Great Vibes", cursive;
  color: white;
  font-size: 40px;
  padding-left: 10px;
}
.about_card {
  width: 400px;
  margin-top: 50px;
}

.about ul {
  list-style-image: url(/src/img/first_page/mark.png);
  width: 400px;
}
.about li {
  margin-bottom: 40px;
  line-height: 1.7;
  font-size: 20px;
}
.title-img {
  background-color: rgb(55, 55, 65);
}
.foto {
  display: flex;
  padding: 0 35px;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}
.h2 {
  margin-top: 100px;
  text-align: center;
  font-size: 50px;
  text-transform: uppercase;
  color: #262674;
}

.hr {
  height: 10px;
  border: 0;
  box-shadow: 0 10px 10px -10px #8c8b8b inset;
  width: 50%;
  margin: 0 auto;
}

.payment {
  width: 100%;
  height: auto;
  background: #262674;
}

.pay_img {
  width: 50%;
  background-image: url(/src/img/first_page/payment.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 0%;
}
.pay_inform {
  width: 50%;
  color: #ffffff;
  font-size: 22px;
}
.beznal,
.document {
  width: 80%;
  margin: 20px auto;
  align-items: center;
  line-height: 1.5;
}
.payment img {
  margin-right: 20px;
  width: 60%;
  height: auto;
}
.beznal p {
  font-size: 1 em;
}
.document p {
  font-size: 1 em;
}

/* ***************************** */

.body_slider_rev {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
}
.slide-container {
  max-width: 1120px;
  width: 100%;
  padding: 40px 0;
}
.slide-content {
  margin: 0 40px;
  overflow: hidden;
  border-radius: 25px;
}
.card {
  height: 400px;
  border-radius: 25px;
  background-color: #fff;
}
.image-content,
.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 46px 14px;
}
.image-content {
  position: relative;
  row-gap: 5px;
  padding: 25px 0;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #262674;
  border-radius: 25px 25px 0 0;
}
.overlay::before,
.overlay::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -40px;
  height: 40px;
  width: 40px;
  background-color: #000080;
}
.overlay::after {
  border-radius: 0 25px 0 0;
  background-color: #fff;
}
.card-image {
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: #fff;
  padding: 3px;
}
.card-image .card-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #262674;
}
.name {
  font-size: 4vh;
  font-weight: 500;
  color: #333;
}
.description {
  font-size: 3vh;
  color: #707070;
  text-align: center;
}
.button {
  border: none;
  font-size: 16px;
  color: #fff;
  padding: 8px 16px;
  background-color: #262674;
  border-radius: 6px;
  margin: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button:hover {
  background: #262674;
}
.swiper-navBtn {
  color: #262674;
  transition: color 0.3s ease;
}
.swiper-navBtn:hover {
  color: #262674;
}
.swiper-navBtn::before,
.swiper-navBtn::after {
  font-size: 35px;
}
.swiper-button-next {
  right: 0;
}
.swiper-button-prev {
  left: 0;
}
.swiper-pagination-bullet {
  background-color: #262674;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: #262674;
}
@media screen and (max-width: 768px) {
  .slide-content {
    margin: 0 10px;
  }
  .swiper-navBtn {
    display: none;
  }
}
/* ******************************************** */
.container {
  max-width: 1170px;
  margin: auto;
}
.logo-footer img {
  margin-right: 25px;
  width: 70px;
  padding-bottom: 20px;
}
.logo-footer {
  align-items: center;
  justify-content: center;
}

.logo-footer p {
  width: 200px;
  font-family: "Great Vibes", cursive;
  color: white;
  font-size: 40px;
  padding-left: 10px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
ul {
  list-style: none;
}

.footer {
  background-color: #24262b;
  padding: 20px 0;
}
.footer-col {
  width: 25%;
  padding: 0 15px;
}
.footer-col li {
  align-items: center;
}
.footer-col img {
  width: 30px;
  margin-right: 10px;
}
.footer-col h4 {
  margin-bottom: 35px;

  position: relative;
}
.footer-col h4::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #262674;
  height: 3px;
  box-sizing: border-box;
  width: 100px;
}
.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}
.footer-col ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  display: block;
  transition: all 0.3s ease;
}
.footer-col ul li a:hover {
  color: #ffffff;
  padding-left: 8px;
}
.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}

.footer-col .social-links .fa-instagram {
  text-align: center;
  background-image: url("/src/img/sotial/instagram.png");
  background-position: center;
  background-size: 84%;
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 10px 10px 0;
  line-height: 40px;
  transition: all 0.5s;
  display: inline-block;
}
.footer-col .social-links .fa-tiktok {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-image: url("/src/img/sotial/tiktok.png");
  background-position: center;
  background-size: 84%;
  background-color: #fff;
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  transition: all 0.5s ease;
}
.footer-col .social-links .fa-tiktok:hover {
  -webkit-box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 1);
}

.footer-col .social-links .fa-instagram:hover {
  -webkit-box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 1);
}

/*responsive*/
@media (max-width: 767px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
}
@media (max-width: 574px) {
  .footer-col {
    width: 100%;
  }
}

.back-to-top {
  position: fixed;
  right: 10px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  border-color: transparent;
  border-radius: 50%;
  background-color: #2828ce4f;
  z-index: 1;
}
.back-to-top:hover,
.back-to-top:focus {
  filter: brightness(1.5);
  outline: none;
}

.back-to-top::before,
.back-to-top::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.back-to-top::before {
  width: 5px;
  height: 25px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #f5f0f6;
}

.back-to-top::after {
  width: 15px;
  height: 15px;
  transform: translate(-50%, -50%) rotate(45deg);
  border-top: 5px solid #f5f0f6;
  border-left: 5px solid #f5f0f6;
}
.gallery {
  display: flex;
  flex-wrap: wrap;
}

.gallery__item {
  padding: 16px 8px;
  flex: 0 0 auto;
  width: calc(100% / 4);
}
.load_more_btn {
  display: block;
  width: 300px;
  height: 50px;
  color: white;
  background-color: #262674;
  border: none;
  margin: 20px auto;
  font-size: 17px;
  border-radius: 5px;
}

/* ************************************************************** */

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-next.swiper-button-hidden,
.swiper-button-prev.swiper-button-hidden {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}
.swiper-navigation-disabled .swiper-button-next,
.swiper-navigation-disabled .swiper-button-prev {
  display: none !important;
}
.swiper-button-next svg,
.swiper-button-prev svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform-origin: center;
}
.swiper-rtl .swiper-button-next svg,
.swiper-rtl .swiper-button-prev svg {
  transform: rotate(180deg);
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 10px);
  right: auto;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 10px);
  left: auto;
}
.swiper-button-lock {
  display: none;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "prev";
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 10px);
  left: auto;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "next";
}
.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}
.swiper-pagination-disabled > .swiper-pagination,
.swiper-pagination.swiper-pagination-disabled {
  display: none !important;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: var(--swiper-pagination-bottom, 8px);
  top: var(--swiper-pagination-top, auto);
  left: 0;
  width: 100%;
}
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}
.swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 8px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 8px)
  );
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}
button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.swiper-pagination-bullet:only-child {
  display: none !important;
}
.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}
.swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-vertical > .swiper-pagination-bullets {
  right: var(--swiper-pagination-right, 8px);
  left: var(--swiper-pagination-left, auto);
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}
.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
  display: block;
}
.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}
.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet,
.swiper-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  display: inline-block;
  transition: 0.2s transform, 0.2s top;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
}
.swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  transition: 0.2s transform, 0.2s left;
}
.swiper-horizontal.swiper-rtl
  > .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  transition: 0.2s transform, 0.2s right;
}
.swiper-pagination-fraction {
  color: var(--swiper-pagination-fraction-color, inherit);
}
.swiper-pagination-progressbar {
  background: var(
    --swiper-pagination-progressbar-bg-color,
    rgba(0, 0, 0, 0.25)
  );
  position: absolute;
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transform-origin: left top;
}
.swiper-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  transform-origin: right top;
}
.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal,
.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite,
.swiper-vertical
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: var(--swiper-pagination-progressbar-size, 4px);
  left: 0;
  top: 0;
}
.swiper-horizontal
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-vertical,
.swiper-vertical > .swiper-pagination-progressbar {
  width: var(--swiper-pagination-progressbar-size, 4px);
  height: 100%;
  left: 0;
  top: 0;
}
.swiper-pagination-lock {
  display: none;
}
