@media screen and (max-width: 1200px) {
  #nav-menu {
    display: none;
  }

  .logo {
    width: 190px;
  }
  .login {
    color: #fff;
    cursor: pointer;
    background: none;
    border: none;
    border-radius: 5px;
    width: 100px;
    /* height: 30px; */
    margin-top: 10px;
    margin-left: 0px;
    font-size: 17px;
    /* font-weight: 600; */
  }
  .user_icon {
    width: 50px;
  }
  .logo_2 p {
    color: #fff;
    width: 82px;
    padding-left: 10px;
    font-family: Great Vibes, cursive;
    font-size: 46px;
    margin-top: 7%;
  }
  .logo_2 img {
    width: 25%;
    margin-right: 0;
    margin-top: 1%;
  }
  nav ul li {
    padding-top: 0px;
    margin: 0 2px;
  }
  h1 {
    font-size: 50px;
  }
  .section_main img {
    width: 80px;
  }
  .section_main h3 {
    font-size: 35px;
  }
  .first-page-btn {
    font-size: 1.3vw;
  }
  .item {
    width: 26%;
  }
  .item h2 {
    font-size: 24px;
  }
  .about {
    color: #fff;
    background: #262674;
    justify-content: space-around;
    width: 100%;
    /* margin-top: 50px; */
    margin: 50px auto;
  }
  .item p {
    text-align: center;
    padding: 20px 42px;
    line-height: 23px;
  }
  /* .about {
    justify-content: center;
  } */

  .left {
    margin-right: 2%;
  }

  .right {
    margin-left: 2%;
  }
  .logo2 {
    margin-right: 0;
  }
  .beznal,
  .document {
    margin: 10% auto;
    font-size: 1 em;
  }

  .name {
    font-size: 26px;
  }
  .description {
    font-size: 21px;
  }
  .body_slider_rev {
    min-height: 60vh;
  }
}

@media screen and (max-width: 992px) {
  #nav-menu {
    display: none;
  }

  .service {
    flex-direction: column;
    /* width: 100%; */
  }
  .about {
    flex-direction: column;
  }
  .about_card {
    margin: 0 auto;
  }

  .logo2 p {
    text-align: center;
  }
  .logo2 {
    margin: 20px auto;
  }
  .about_card {
    width: 700px;
  }
  .about ul {
    width: 100%;
    text-align: justify;
  }
  .left {
    padding-top: 30px;
  }
  .item {
    width: 50%;
    margin: 20px auto;
  }

  .beznal,
  .document {
    margin: 10% auto;
    font-size: 1 em;
  }
  .body_slider_rev {
    min-height: 60vh;
  }
}

@media screen and (max-width: 768px) {
  #nav-menu {
    display: none;
  }
  nav .logo p {
    font-size: 32px;
    max-width: 150px;
    padding-left: 7px;
  }
  nav .logo {
    padding: 10px 0;
    margin-left: -20px;
  }
  h1 {
    padding-top: 40px;
  }
  .first-page-btn {
    width: 30%;
    font-size: 1.7vw;
  }
  .about_card {
    width: 650px;
    font-size: 20px;
  }
  .about ul {
    width: 100%;
    text-align: justify;
  }
  .pay_inform {
    width: 100%;
  }
  nav ul li a {
    padding: 6px 5px;
    font-size: 11px;
  }
  .body_slider_rev {
    min-height: 60vh;
  }
}

@media screen and (max-width: 576px) {
  #nav-menu {
    display: none;
  }
  .burger-menu {
    display: block;
  }
  .description {
    font-size: 19px;
  }
  h1 {
    padding-top: 85px;
  }
  .section_main h2 {
    padding-top: 20px;
  }
  .section_main img {
    width: 67px;
  }
  .item {
    width: 75%;
    margin: 20px auto;
  }
  .section_main h3 {
    font-size: 28px;
  }

  .first-page-btn {
    margin: 80px auto;
    width: 40%;
    font-size: 2.5vw;
  }
  .about_card {
    width: 450px;
  }
  .about ul {
    width: 95%;
    margin: 10px auto;
    text-align: justify;
  }
  .pay_inform {
    width: 100%;
  }
  .beznal,
  .document {
    width: 90%;
    margin-top: 123px;
    margin-left: 20px;
    /* text-align: justify;
align-items: center; */
    line-height: 1.5;
    font-size: 20px;
  }
  .pay_img {
    width: 0%;
  }
  .payment img {
    width: 70px;
  }
  .gallery__item {
    width: 50%;
  }
  nav ul li a {
    padding: 6px 2px;
    font-size: 11px;
  }
  .beznal,
  .document {
    width: 90%;
    margin-top: 10%;
    margin-left: 20px;
    font-size: 20px;
    line-height: 1.5;
  }
  .body_slider_rev {
    min-height: 60vh;
  }
}
@media screen and (max-width: 430px) {
  .first-page-btn {
    width: 90%;
    margin: 100px auto;
    font-size: 4.5vw;
    font-weight: 700;
  }
  .back-to-top {
    right: 20px;
    z-index: 9;
  }
  .about ul {
    text-align: justify;
    width: 77%;
    margin: 10px auto;
  }
  .description {
    font-size: 16px;
  }
  .service {
    margin: 0 auto;
  }
  .about {
    margin-top: 20px;
  }
  .about_card {
    width: 95%;
  }
  .h2 {
    margin-top: 72px;
  }
  .body_slider_rev {
    min-height: 60vh;
  }
}
