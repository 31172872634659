.wrapper-gallery {
  max-width: 1200px;
  width: 100%;
  position: relative;
}
/* .fa-solid,
.fas {
  font-weight: 900;
} */
.wrapper-gallery i {
  top: 50%;
  height: 50px;
  width: 50px;
  cursor: pointer;
  font-size: 1.25rem;
  position: absolute;
  text-align: center;
  line-height: 50px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.23);
  transform: translateY(-50%);
  transition: transform 0.1s linear;
}
.wrapper-gallery i:active {
  transform: translateY(-50%) scale(0.85);
}
.wrapper-gallery i:first-child {
  left: -22px;
  z-index: 2;
}
.wrapper-gallery i:last-child {
  right: -22px;
}
.wrapper-gallery .carousel-list {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: calc((100% / 3) - 12px);
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 16px;
  border-radius: 8px;
  scroll-behavior: smooth;
  scrollbar-width: none;
}
.carousel-list::-webkit-scrollbar {
  display: none;
}
.carousel-list.no-transition {
  scroll-behavior: auto;
}
.carousel-list.dragging {
  scroll-snap-type: none;
  scroll-behavior: auto;
}
.carousel-list.dragging .card-gallery {
  cursor: grab;
  user-select: none;
}
.carousel-list :where(.card-gallery, .img) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-gallery img {
  width: 100%;
  height: 60%;
  position: relative;
}
.carousel-list .card-gallery {
  /* scroll-snap-align: start; */
  /* width: 288px;
  height: 288px; */
  list-style: none;
  /* background: #fff; */
  cursor: pointer;
  /* padding-bottom: 15px; */
  flex-direction: column;
  border-radius: 8px;
}
.carousel-list .card-gallery .img {
  background: #8b53ff;
  height: 148px;
  width: 148px;
  border-radius: 50%;
}
.card-gallery .img img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #fff;
}
.carousel-list .card-gallery h2 {
  font-weight: 500;
  font-size: 1.56rem;
  margin: 30px 0 5px;
}
.carousel-list .card-gallery span {
  color: #6a6d78;
  font-size: 1.31rem;
}

@media screen and (max-width: 900px) {
  .wrapper-gallery .carousel-list {
    grid-auto-columns: calc((100% / 2) - 9px);
  }
}

@media screen and (max-width: 600px) {
  .wrapper-gallery .carousel-list {
    grid-auto-columns: 100%;
  }
}
