#menuToggle {
  display: none;
}
#nav-menu img {
  display: none;
}
#nav-menu.open {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #262674;
  z-index: 10;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding-top: 30px;
}
@media screen and (max-width: 1200px) {
  #menuToggle {
    display: block;
    cursor: pointer;
  }
  #menuToggle img {
    width: 40px;
  }
  #nav-menu img {
    display: block;
    width: 32px;
    height: auto;
    margin-left: 94%;
    /* margin-top: 3%; */
    cursor: pointer;
  }
}

@media screen and (max-width: 992px) {
  #menuToggle {
    display: block;
  }
  #menuToggle img {
    width: 30px;
  }
  nav ul li a {
    padding: 6px 32px;
  }
}

@media screen and (max-width: 768px) {
  #menuToggle {
    display: block;
  }
  nav ul li a {
    padding: 6px 32px;
    font-size: 14px;
  }
  #nav-menu img {
    width: 31px;
    margin-left: 83%;
  }
}

@media screen and (max-width: 576px) {
  #menuToggle {
    display: block;
  }
  nav ul li a {
    padding: 5px 10px;
    font-size: 15px;
  }
  #nav-menu img {
    width: 28px;
    margin-left: 89%;
  }
}
